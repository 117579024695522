import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  linkActiveClass: 'is-active',
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/token']
  if (isAuthenticated && to.path === '/login') {
    next('/')
  }
  if (!isAuthenticated && to.path !== '/login' && to.path !== '/forgot-password') {
    if (from.path !== '/login') {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
