var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login login-with-news-feed"},[_vm._m(0),_c('div',{staticClass:"login-container"},[_vm._m(1),_c('div',{staticClass:"login-content"},[_c('form',{staticClass:"fs-13px",attrs:{"method":"GET"},on:{"submit":_vm.checkForm}},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"mb-40px pb-40px text-inverse"},[_vm._v(" Not a member yet? Click "),_c('router-link',{staticClass:"text-success",attrs:{"to":"/user/register-v3"}},[_vm._v("here")]),_vm._v(" to register. ")],1),_c('hr',{staticClass:"bg-gray-600 opacity-2"}),_c('div',{staticClass:"text-gray-600 text-center text-gray-500-darker mb-0"},[_vm._v(" © Color Admin All Right Reserved 2021 ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-feed"},[_c('div',{staticClass:"news-image",staticStyle:{"background-image":"url(/assets/img/login-bg/login-bg-11.jpg)"}}),_c('div',{staticClass:"news-caption"},[_c('h4',{staticClass:"caption-title"},[_c('b',[_vm._v("Color")]),_vm._v(" Admin App")]),_c('p',[_vm._v(" Download the Color Admin app for iPhone®, iPad®, and Android™. Lorem ipsum dolor sit amet, consectetur adipiscing elit. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-header mb-30px"},[_c('div',{staticClass:"brand"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"logo"}),_c('b',[_vm._v("Color")]),_vm._v(" Admin ")]),_c('small',[_vm._v("Bootstrap 5 Responsive Admin Template")])]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-sign-in-alt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-floating mb-15px"},[_c('input',{staticClass:"form-control h-45px fs-13px",attrs:{"type":"text","placeholder":"Email Address","id":"emailAddress"}}),_c('label',{staticClass:"d-flex align-items-center fs-13px text-gray-600",attrs:{"for":"emailAddress"}},[_vm._v("Email Address")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-floating mb-15px"},[_c('input',{staticClass:"form-control h-45px fs-13px",attrs:{"type":"password","placeholder":"Password","id":"password"}}),_c('label',{staticClass:"d-flex align-items-center fs-13px text-gray-600",attrs:{"for":"password"}},[_vm._v("Password")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check mb-30px"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","value":"1","id":"rememberMe"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"rememberMe"}},[_vm._v(" Remember Me ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-15px"},[_c('button',{staticClass:"btn btn-success d-block h-45px w-100 btn-lg fs-14px",attrs:{"type":"submit"}},[_vm._v("Sign me in")])])
}]

export { render, staticRenderFns }