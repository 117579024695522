import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import store from './store'

import './mixins'
import './plugins'

import App from './App.vue'

Vue.config.productionTip = false

Vue.use(Vuex)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
