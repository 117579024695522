<template>
	<svg :width="width" :height="height" :style="styleName" :class="className" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M13.0991 12.8331C14.5379 12.8623 15.757 13.9012 16.0141 15.3173L16.2249 16.3623C16.4331 17.2642 16.2284 18.2121 15.6665 18.9477C15.1046 19.6833 14.244 20.1301 13.3191 20.1665H4.80322C3.87698 20.1334 3.0142 19.6874 2.45159 18.9509C1.88899 18.2143 1.68565 17.2646 1.89739 16.3623L2.10822 15.3173C2.36125 13.9027 3.57729 12.8631 5.01405 12.8331H13.0991ZM13.3191 18.7915C13.7823 18.7873 14.218 18.5711 14.5016 18.2048C14.865 17.7662 15.0038 17.1835 14.8774 16.6281L14.6666 15.5831C14.5372 14.8091 13.8832 14.2316 13.0991 14.199H5.01405C4.23205 14.2323 3.58111 14.8105 3.45572 15.5831L3.29072 16.6373C3.16726 17.1898 3.30597 17.7684 3.66655 18.2048C3.94004 18.5588 4.35621 18.7736 4.80322 18.7915H13.3191Z" :fill="color" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M9.51489 10.9998H8.59822C6.57317 10.9998 4.93155 9.35818 4.93155 7.33313V4.91313C4.93155 3.21209 6.31051 1.83313 8.01155 1.83313H10.0832C10.904 1.82578 11.6936 2.14732 12.2757 2.726C12.8578 3.30468 13.184 4.09232 13.1816 4.91313V7.33313C13.1816 9.35818 11.5399 10.9998 9.51489 10.9998ZM8.01155 3.20813C7.06991 3.20813 6.30655 3.97149 6.30655 4.91313V7.33313C6.31147 8.58961 7.32348 9.60979 8.57989 9.6248H9.49655C10.1043 9.6248 10.6872 9.38336 11.117 8.95358C11.5468 8.52381 11.7882 7.94092 11.7882 7.33313V4.91313C11.7882 3.97149 11.0249 3.20813 10.0832 3.20813H8.01155Z" :fill="color" />
		<path d="M19.5982 5.72897H18.3332V4.47313C18.3332 4.09344 18.0254 3.78563 17.6457 3.78563C17.266 3.78563 16.9582 4.09344 16.9582 4.47313V5.72897H15.7115C15.3319 5.72897 15.024 6.03677 15.024 6.41646C15.024 6.79616 15.3319 7.10396 15.7115 7.10396H16.9674V8.3598C16.9674 8.73949 17.2752 9.0473 17.6549 9.0473C18.0346 9.0473 18.3424 8.73949 18.3424 8.3598V7.10396H19.5982C19.9779 7.10396 20.2857 6.79616 20.2857 6.41646C20.2857 6.03677 19.9779 5.72897 19.5982 5.72897Z" :fill="color" />
	</svg>
</template>

<script>
  export default {
    name: 'IconAddUser',
	props: {
      'width': {
		type: Number,
		default: 22,
	  },
	  'height': {
        type: Number,
        default: 22,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#2B2A29'
	  },
	  'className': {
        type: String,
		default: ''
	  }
	},
    data() {
      return {}
    },
  }
</script>
