import axios from 'axios'
import appOptions from '../../../config/options'

export default {
  async fetchLogin({ rootState, commit }, payload) {
    try {
      const res = await axios({
        url: appOptions.api + '/auth/login/',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
        },
        data: payload,
      })
      await res.data ? commit('SET_TOKEN', res.data?.data?.token) : commit('toast/NEW', {
        type: 'error',
        message: res.data?.data?.message,
      }, { root: true })

      return res.data?.data
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err.response?.data
    }
  },
  logout({ rootState, commit }) {
    commit('SET_TOKEN', null)
  },
}
