<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb d-none d-md-flex">
			<li class="breadcrumb-item">
				<router-link to="/">Главная</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link to="/">Личный кабинет</router-link>
			</li>
			<li class="breadcrumb-item active">Мои данные</li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
			<h1 class="page-header">Мои данные</h1>
		</div>
		<!-- END page-header -->
		<div class="mw-900px">
			<!-- BEGIN page-header -->
			<ValidationObserver
					tag="form"
					class="row "
					ref="profile_observer"
					@submit.prevent="addSubmit()"
					v-slot="{ invalid }"
					method="POST">

				<ValidationProvider
						ref="form-profile-fio"
						tag="div"
						class="mb-15px position-relative col-12 col-sm-6"
						rules="required"
						name="ФИО*"
						v-slot="{ errors }">

					<label :for="'form-profile-field-fio'" class="form-label">ФИО*</label>

					<input type="text"
						   class="form-control"
						   :class="errors.length ? 'is-invalid' : ''"
						   name="form-profile-field-fio"
						   id="form-profile-field-fio"
						   @input="checkValidate('fio')"
						   @change="checkValidate('fio')"
						   v-model="form['fio']" />

					<div v-if="errors.length" class="invalid-tooltip">
						<span v-for="err in errors" :key="'form-profile-field-fio_err'">{{err}}</span>
					</div>

				</ValidationProvider>

			</ValidationObserver>
			<!-- END page-header -->
		</div>
	</div>
</template>

<script>
  export default {
    name: 'Profile',
    data() {
      return {
        form: {
		  fio: '',
		  phone: '',
		  email: '',
		  snils: '',
		}
	  }
    },
	watch: {
	},
    computed: {
    },
    created() {
    },
    methods: {
      checkValidate: function (name) {
        const provider = this.$refs[name]
        if (provider && provider.validate) {
          provider.validate()
        }
      },
      addSubmit() {

	  }
    },
  }
</script>
