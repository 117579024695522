import state from './listenersState'
import mutations from './listenersMutations'
import getters from './listenersGetters'
import actions from './listenersActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
