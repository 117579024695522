/**
 * Format phone number from 79999999999 to +7 (999) 999-99-99
 */
export default {
  methods: {
    $formatPhone(phone) {
      return phone ? '+' + phone.replace(/[^0-9]/ig, '').replace(/^(\+7|7|8)?[\s\-]?\(?([489][0-9]{2})\)?[\s\-]?([0-9]{3})[\s\-]?([0-9]{2})[\s\-]?([0-9]{2})$/, '$1 ($2) $3-$4-$5') : ''
    },
  },
}
