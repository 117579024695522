<template>
	<svg :width="width" :height="height" :style="styleName" :class="className" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M2.59123 13.7804L1.89456 18.6204C1.84425 18.9931 1.95755 19.3693 2.2053 19.6522C2.45304 19.9351 2.81103 20.097 3.18706 20.0963H3.37956L8.21956 19.3996C8.87562 19.3092 9.48408 19.0065 9.95206 18.5379L18.8804 9.60044C20.6174 7.80203 20.5925 4.94338 18.8246 3.17544C17.0566 1.40749 14.198 1.38265 12.3996 3.1196L3.4529 12.0479C2.98429 12.5159 2.68168 13.1244 2.59123 13.7804ZM8.93456 17.5663C8.68102 17.8098 8.36283 17.9752 8.01789 18.0429L3.24206 18.6754L3.91123 13.9363C3.97895 13.5913 4.14441 13.2731 4.3879 13.0196L13.3254 4.09127C13.9276 3.47305 14.754 3.12435 15.6171 3.12435C16.4801 3.12435 17.3065 3.47305 17.9087 4.09127C18.5127 4.69176 18.8524 5.50831 18.8524 6.36002C18.8524 7.21173 18.5127 8.02828 17.9087 8.62877L8.93456 17.5663Z" :fill="color" />
		<path d="M15.1862 5.8421C15.4571 5.58969 15.8792 5.59714 16.141 5.85895C16.4029 6.12076 16.4103 6.54289 16.1579 6.81377L12.2987 10.6729C12.0303 10.941 11.5955 10.941 11.3271 10.6729C11.059 10.4045 11.059 9.96969 11.3271 9.70127L15.1862 5.8421Z" :fill="color" />
	</svg>
</template>

<script>
  export default {
    name: 'IconPen',
	props: {
      'width': {
		type: Number,
		default: 22,
	  },
	  'height': {
        type: Number,
        default: 22,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#2B2A29'
	  },
      'className': {
        type: String,
        default: ''
      },
    },
    data() {
      return {}
    },
  }
</script>
