import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import modules from './modules'

Vue.use(Vuex)

const dataState = createPersistedState({
  paths: ['user', 'order', 'auth', 'toast']
})

export default new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV === 'development',
  plugins: [dataState],
})
