<template>
	<!-- BEGIN error -->
	<div class="error">
		<div class="error-code">404</div>
		<div class="error-content">
			<div class="error-message">Страница не найдена</div>
			<div class="error-desc mb-4">
				Неправильно набран адрес или такой страницы не существует
			</div>
			<div>
				<router-link to="/" class="btn btn-success px-3">На главную</router-link>
			</div>
		</div>
	</div>
	<!-- END error -->
</template>

<script>
import AppOptions from '../config/options.js'

export default {
	created() {
		AppOptions.appEmpty = true;
	},
	beforeRouteLeave (to, from, next) {
		AppOptions.appEmpty = false;
		next();
	}
}
</script>
