/**
 * import and init global mixins
 */

import Vue from 'vue'

import jumpTo from '../mixins/jumpTo'
import formatDateTime from '../mixins/formatDateTime'
import formatPhone from '../mixins/formatPhone'
import formatPrice from '../mixins/formatPrice'
import withoutWatchers from '../mixins/withoutWatchers'

Vue.mixin(jumpTo)
Vue.mixin(formatDateTime)
Vue.mixin(formatPhone)
Vue.mixin(formatPrice)
Vue.mixin(withoutWatchers)
