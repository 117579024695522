export default {
  last_page: state => state.pagination.last_page,
  itemsByPage: state => {
    let items = []
    for (let key in state.items) {
      if (state.items.hasOwnProperty(key) && state.pagination.cur_ids.indexOf(key.toString()) >= 0) {
        items.push(state.items[key])
      }
    }
    return items
  },
  itemById: state => id => {
    for (let key in state.items) {
      if (state.items.hasOwnProperty(key) && Number(state.items[key].id) === Number(id)) {
        return state.items[key]
      }
    }
    return null
  },
}
