<template>
	<svg :width="width" :height="height" :style="styleName" :class="className" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M17.2245 6.41634C16.8245 6.41634 16.5003 6.74056 16.5003 7.14051V15.2438C16.5003 16.9499 15.1173 18.333 13.4112 18.333H8.20449C6.49839 18.333 5.11533 16.9499 5.11533 15.2438V7.14051C5.11533 6.74056 4.79111 6.41634 4.39116 6.41634C3.99121 6.41634 3.66699 6.74056 3.66699 7.14051V15.2438C3.69213 17.7572 5.73681 19.7815 8.25033 19.7813H13.457C15.9526 19.7565 17.9697 17.7395 17.9945 15.2438V7.14051C17.9949 6.94024 17.9123 6.74874 17.7665 6.61153C17.6206 6.47433 17.4244 6.40366 17.2245 6.41634Z" :fill="color"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M17.2245 5.11467H4.39116C3.99121 5.11467 3.66699 4.79045 3.66699 4.39051C3.66699 3.99056 3.99121 3.66634 4.39116 3.66634H6.82033L7.90199 2.49301C8.31547 2.07263 8.87985 1.835 9.46949 1.83301H12.1462C12.7272 1.833 13.2845 2.06382 13.6953 2.47467L14.7953 3.66634H17.2245C17.6244 3.66634 17.9487 3.99056 17.9487 4.39051C17.9487 4.79045 17.6244 5.11467 17.2245 5.11467ZM12.1462 3.28134H9.46949C9.28624 3.2814 9.10964 3.35008 8.97449 3.47384L8.80033 3.65717H12.8153L12.6412 3.47384C12.507 3.3485 12.3298 3.27957 12.1462 3.28134Z" :fill="color"/>
		<path d="M7.37032 8.05717V10.8072C7.37032 11.1869 7.67813 11.4947 8.05782 11.4947C8.43752 11.4947 8.74532 11.1869 8.74532 10.8072V8.05717C8.74532 7.67748 8.43752 7.36967 8.05782 7.36967C7.67813 7.36967 7.37032 7.67748 7.37032 8.05717Z" :fill="color"/>
		<path d="M10.1203 8.05717V15.3905C10.1203 15.7702 10.4281 16.078 10.8078 16.078C11.1875 16.078 11.4953 15.7702 11.4953 15.3905V8.05717C11.4953 7.67748 11.1875 7.36967 10.8078 7.36967C10.4281 7.36967 10.1203 7.67748 10.1203 8.05717Z" :fill="color"/>
		<path d="M12.8703 8.05717V10.8072C12.8703 11.1869 13.1781 11.4947 13.5578 11.4947C13.9375 11.4947 14.2453 11.1869 14.2453 10.8072V8.05717C14.2453 7.67748 13.9375 7.36967 13.5578 7.36967C13.1781 7.36967 12.8703 7.67748 12.8703 8.05717Z" :fill="color"/>
	</svg>
</template>

<script>
  export default {
    name: 'IconTrash',
	props: {
      'width': {
		type: Number,
		default: 22,
	  },
	  'height': {
        type: Number,
        default: 22,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#2B2A29'
	  },
      'className': {
        type: String,
        default: ''
      },
    },
    data() {
      return {}
    },
  }
</script>
