<template>
	<div class="menu">
		<div class="menu-profile">
			<div class="menu-profile-link">
				<!--<div class="menu-profile-cover with-shadow"></div>-->
				<div class="menu-profile-image">
					<img :src="LogoUser" alt="" />
				</div>
				<div class="menu-profile-info">
					<div class="d-flex align-items-center">
						<div class="flex-grow-1">
							Кристина Александровна Иванов
						</div>
					</div>
					<small>bodytext</small>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import AppOptions from '../../config/options.js'
  import LogoUser from '../../../public/assets/img/user/user.svg'

  export default {
    name: 'SidebarNavProfile',
    data() {
      return {
        LogoUser: LogoUser,
        stat: '',
        appOptions: AppOptions,
      }
    },
    methods: {},
  }
</script>
