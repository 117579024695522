<template>
	<div>
		<ValidationObserver
				tag="form"
				class="row mt-3"
				ref="payer_edit_observer_legal"
				@submit.prevent="addSubmit()"
				v-slot="{ invalid }"
				method="POST">

			<div :class="'col-12 col-sm-' + field.width + (field.offset ? 'offset-0 offset-sm-' + field.offset : '')"
				 v-for="(field, key) in typesFieldsMap"
				 :key="key">
				<ValidationProvider
						:ref="'form-payer-' + key"
						tag="div"
						class="mb-15px position-relative"
						:rules="field.rules"
						:name="field.label"
						v-slot="{ errors }">
					<template v-if="field.tag === 'input'">
						<!---INPUT TEXT--->
						<template v-if="field.type === 'text'">

							<label :for="'form-payer-field-' + key"
								   class="form-label"
								   v-if="field.label"
								   v-html="field.label"></label>

							<input type="text"
								   class="form-control"
								   :class="errors.length ? 'is-invalid' : ''"
								   :placeholder="field.placeholder"
								   :name="'form-payer-field-' + key"
								   :id="'form-payer-field-' + key"
								   :maxlength="field.maxlength || 10000"
								   @keypress="field.rules.indexOf('digits') >= 0 || field.rules.indexOf('numeric') >= 0 ? NumbersOnly() : null"
								   @input="checkValidate(field.field_name || key)"
								   @change="checkValidate(field.field_name || key)"
								   v-model="form[field.field_name || key]" />

							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>

						</template>
						<!---MASKED TEXT--->
						<template v-if="field.type === 'mask'">

							<label :for="'form-payer-field-' + key"
								   class="form-label"
								   v-if="field.label"
								   v-html="field.label"></label>

							<masked-input type="text"
										  class="form-control"
										  :class="errors.length ? 'is-invalid' : ''"
										  :placeholder="field.placeholder"
										  :name="'form-payer-field-' + key"
										  :id="'form-payer-field-' + key"
										  :maxlength="field.maxlength || 10000"
										  :pattern="field.mask"
										  @input="checkValidate(field.field_name || key)"
										  @change="checkValidate(field.field_name || key)"
										  v-model="form[field.field_name || key]">

							</masked-input>

							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>

						</template>
						<!---DADATA TYPE--->
						<template v-if="field.type === 'suggestion'">

							<label :for="'form-payer-field-' + key"
								   class="form-label"
								   v-if="field.label"
								   v-html="field.label"></label>

							<div class="position-relative" v-click-outside="clickOutsideSuggestions">
								<input type="text"
									   class="form-control"
									   :class="errors.length ? 'is-invalid' : ''"
									   :placeholder="field.placeholder"
									   :name="'form-payer-field-' + key"
									   :id="'form-payer-field-' + key"
									   :maxlength="field.maxlength || 10000"
									   @keypress="field.rules.indexOf('digits') >= 0 || field.rules.indexOf('numeric') >= 0 ? NumbersOnly() : null"
									   @focusin="field.showSuggestions = true"
									   @input="getData(field.field_name || key, key)"
									   @change="checkValidate(field.field_name || key)"
									   v-model="form[field.field_name || key]" />

								<vue-custom-scrollbar class="suggestions-list" v-if="field.suggestions && field.showSuggestions">
									<div class="suggestions-item"
										 v-for="(s_item, index) in field.suggestions"
										 :key="'s_' + index"
										 @click="selectSuggestionValue(key, s_item)">
										{{s_item.name}}
									</div>
								</vue-custom-scrollbar>
							</div>

							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>

						</template>
						<!---SEARCH TYPE--->
						<template v-if="field.type === 'search'">

							<label :for="'form-payer-field-' + key"
								   class="form-label"
								   v-if="field.label"
								   v-html="field.label"></label>
							<div class="input-group">
								<input type="text"
									   class="form-control"
									   :class="errors.length ? 'is-invalid' : ''"
									   :placeholder="field.placeholder"
									   :name="'form-payer-field-' + key"
									   :id="'form-payer-field-' + key"
									   :maxlength="field.maxlength || 10000"
									   @input="checkValidate(field.field_name || key)"
									   @change="checkValidate(field.field_name || key)"
									   v-model="form[field.field_name || key]" />
								<button type="button" class="btn btn-primary"><i class="fas fa-search"></i></button>
							</div>
							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>
						</template>
						<!---CHECKBOX--->
						<template v-else-if="field.type === 'checkbox'">
							<div :class="'form-check' + (errors.length ? ' is-invalid' : '')">
								<input type="checkbox"
									   @input="checkValidate(field.field_name || key)"
									   @change="checkValidate(field.field_name || key)"
									   v-model="form[field.field_name || key]"
									   :name="'form-payer-field-' + key"
									   :id="'form-payer-field-' + key"
									   class="form-check-input" />
								<label :for="'form-payer-field-' + key"
									   class="form-label"
									   v-if="field.label"
									   v-html="field.label"></label>
							</div>
							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>
						</template>
						<!---RADIO--->
						<template v-else-if="field.type === 'radio'">
							<div class="form-check mb-2" v-for="value in field.values" :key="value.value">
								<input type="radio"
									   @input="checkValidate(field.field_name || key)"
									   @change="checkValidate(field.field_name || key)"
									   :id="'form-payer-field-' + key + value.value"
									   :name="'form-payer-field-' + key"
									   :value="value.value"
									   v-model="form[field.field_name || key]"
									   class="form-check-input">
								<label :for="'form-payer-field-' + key + value.value"
									   class="form-label"
									   v-if="value.label"
									   v-html="value.label"></label>
							</div>
							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>
						</template>
					</template>
					<!---TEXTAREA--->
					<template v-else-if="field.tag === 'textarea'">

						<label :for="'form-payer-field-' + key"
							   class="form-label"
							   v-if="field.label"
							   v-html="field.label"></label>
						<div :class="field.readonly ? 'readonly' : ''">
									<textarea
											rows="3"
											class="form-control"
											:class="errors.length ? 'is-invalid' : ''"
											:placeholder="field.placeholder"
											:id="'form-payer-field-' + key"
											:name="'form-payer-field-' + key"
											:readonly="field.readonly"
											:maxlength="field.maxlength || 100000"
											@input="checkValidate(field.field_name || key)"
											@change="checkValidate(field.field_name || key)"
											v-model="form[field.field_name || key]"></textarea>
						</div>
						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
						</div>

					</template>
					<!---TEXT--->
					<template v-else-if="field.tag === 'div'">
						<div :class="field.class">{{field.label}}</div>
					</template>

				</ValidationProvider>
			</div>

		</ValidationObserver>
		<div class="d-flex justify-content-center w-100 mt-24px">
			<button type="button" @click="addSubmit()" class="btn btn-primary btn-lg">
				Сохранить
			</button>
		</div>
		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<a class="title">
						{{props.item.title}}
					</a>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import appOptions from '../../config/options'

  export default {
    name: 'LegalForm',
    props: ['button', 'data', 'id'],
    data() {
      return {
        suggestionToken: appOptions.suggestionToken,
        typesFieldsMap: {
          inn: {
            tag: 'input',
            type: 'suggestion',
            fields: {
              inn: 'inn',
              kpp: 'kpp',
              ogrn: 'ogrn',
              full_legal_name: 'full_ur_name',
              abbreviation: 'abbreviation',
              city: 'city',
              index: 'index',
              ur_address: 'ur_address',
              fio_head: 'fio_rod_head',
            },
            suggestions: [],
            showSuggestions: false,
            label: 'ИНН*',
            rules: 'required|min:10|max:12|numeric',
            maxlength: 12,
            width: 4,

			method: 'searchToInn',

            field_name: 'inn',
          },
          full_legal_name: {
            tag: 'input',
            type: 'text',
            label: 'Полное юридическое название',
            rules: '',
            width: 8,

            field_name: 'full_legal_name',
          },
          abbreviation: {
            tag: 'input',
            type: 'text',
            label: 'Сокращенное название',
            rules: '',
            width: 8,

            field_name: 'abbreviation',
          },
          ogrn: {
            tag: 'input',
            type: 'text',
            label: 'ОГРН*',
            rules: 'required|digits:13',
            width: 4,
            maxlength: 13,

            field_name: 'ogrn',
          },
          city: {
            tag: 'input',
            type: 'text',
            label: 'Город*',
            rules: 'required',
            width: 8,

            field_name: 'city',
          },
          index: {
            tag: 'input',
            type: 'text',
            label: 'Индекс*',
            rules: 'required|digits:6',
            maxlength: 6,
            width: 4,

            field_name: 'index',
          },
          ur_address: {
            tag: 'textarea',
            label: 'Юридический адрес* (с индексом)',
            rules: '',
            width: 6,

            field_name: 'ur_address',
          },
          actual_address: {
            tag: 'textarea',
            label: 'Фактический адрес* (с индексом)',
            rules: '',
            width: 6,

            field_name: 'actual_address',
          },
          check: {
            tag: 'input',
            type: 'checkbox',
            label: 'Совпадает с адресом регистрации',
            rules: '',
            width: 6,
            offset: 6,

            field_name: 'check',
          },
          fio_head: {
            tag: 'input',
            type: 'text',
            label: 'ФИО*',
            rules: 'required',
            width: 12,

            field_name: 'fio_head',
          },
          fio_rod_head: {
            tag: 'input',
            type: 'text',
            label: 'ФИО в родительном падеже*',
            rules: 'required',
            width: 12,

            field_name: 'fio_rod_head',
          },
          title: {
            tag: 'div',
            class: 'fs-18px fw-bold mb-16px mt-30px',
            label: 'Банковские реквизиты',
            rules: '',
            width: 12,

          },
          bik_bank: {
            tag: 'input',
            label: 'БИК банка*',
            rules: 'required|digits:9',
            placeholder: 'ХХХХХХХХХ',
            width: 4,
            maxlength: 9,

            type: 'suggestion',
            fields: {
              bik_bank: 'bik_bank',
              name_bank: 'name_bank',
              ks: 'ks',
            },
            suggestions: [],
            showSuggestions: false,

            method: 'searchToBik',

            field_name: 'bik_bank',
          },
          name_bank: {
            tag: 'input',
            type: 'text',
            label: 'Название банка*',
            placeholder: 'Введите название',
            rules: 'required',
            width: 8,

            field_name: 'name_bank',
          },
          rc: {
            tag: 'input',
            type: 'text',
            label: 'р/с*',
            placeholder: 'ХХХХХХХХХХХХХХХХХХХХ',
            rules: 'required|digits:20',
            width: 4,
            maxlength: 20,

            field_name: 'rc',
          },
          ks: {
            tag: 'input',
            type: 'text',
            label: 'к/с*',
            placeholder: 'ХХХХХХХХХХХХХХХХХХХХ',
            rules: 'required|digits:20',
            width: 4,
            maxlength: 20,

            field_name: 'ks',
          },
          kbk: {
            tag: 'input',
            type: 'text',
            label: 'КБК*',
            placeholder: 'ХХХХХХХХХХХХХХХХХХХХ',
            rules: 'required|digits:20',
            width: 4,
            maxlength: 20,

            field_name: 'kbk',
          },
          title2: {
            tag: 'div',
            class: 'mt-9px',
            label: '',
            rules: '',
            width: 12,

          },
          concluded_accordance: {
            tag: 'input',
            type: 'radio',
            rules: 'required',
            width: 12,
            label: 'Основание для действий',
            field_name: 'concluded_accordance',
            values: [
              {
                label: 'Заключается согласно 223-ФЗ',
                value: 'Заключается согласно 223-ФЗ',
              },
              {
                label: 'Заключается с коммерческой организацией',
                value: 'Заключается с коммерческой организацией',
              },
            ],
          },
        },
        form: {
          type_face: 'UR',
          order_id: this.id,
          inn: '',
          ogrn: '',
          city: '',
          index: '',
          abbreviation: '',
          full_legal_name: '',
          ur_address: '',
          actual_address: '',
          bik_bank: '',
          name_bank: '',
          rc: '',
          ks: '',
          kbk: '',
          fio_rod_head: '',
          concluded_accordance: '',
          snils: '',
          check: false,
        },
      }
    },
    computed: {
      ...mapGetters({
        token: 'auth/token',
      }),
      formItems: function () {
        return JSON.parse(JSON.stringify(this.form))
      },
    },
    watch: {
      formItems(newVal, oldVal) {
        this.typesFieldsMap.actual_address.readonly = false
        if (newVal.check) {
          this.form.actual_address = newVal.ur_address
          this.typesFieldsMap.actual_address.readonly = true
        }
      },
      data(newVal, oldVal) {
        for (let key in newVal) {
          if (newVal.hasOwnProperty(key)) {
            this.form[key] = newVal[key]
            if (key === 'snils') {
              this.form[key] = newVal[key].replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`)
            } else {
              this.form[key] = newVal[key]
            }
          }
        }
      },
    },
    mounted() {
      if (this.data) {
        for (let key in this.data) {
          if (this.data.hasOwnProperty(key)) {
            if (key === 'snils') {
              this.form[key] = this.data[key].replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`)
            } else {
              this.form[key] = this.data[key]
            }
          }
        }
      }
    },
    destroyed() {
    },
    methods: {
      clickOutsideSuggestions: function (e) {
        for (let key in this.typesFieldsMap) {
          if (this.typesFieldsMap.hasOwnProperty(key) && this.typesFieldsMap[key].type === 'suggestion') {
            this.typesFieldsMap[key].showSuggestions = false
          }
        }
      },
      selectSuggestionValue: function (key, item) {
        if (this.typesFieldsMap[key]) {
          this.typesFieldsMap[key].showSuggestions = false
          const setFields = (fields, data) => {
            for (let i in data) {
              if (data.hasOwnProperty(i) && this.form[i]) {
                this.form[i] = data[i]
              }
            }
          }
          setFields(this.typesFieldsMap[key], item)
        }
      },
      getData: function (key, keyMap) {
        if (!this.typesFieldsMap[keyMap]) return
        axios({
          url: appOptions.api + `/orders/${this.typesFieldsMap[keyMap].method}/`,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: {
            q: this.form[key]
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res.data) {
              this.typesFieldsMap[keyMap].suggestions = res.data || []
              this.typesFieldsMap[keyMap].showSuggestions = !!res.data.length
            }
          })
      },
      checkValidate: function (name) {
        const provider = this.$refs[name]
        if (provider && provider.validate) {
          provider.validate()
        }
      },
      async addSubmit() {
        const isValid = await this.$refs.payer_edit_observer_legal.validate()
        if (isValid) {
          let params = this.removeEmptyValues(JSON.parse(JSON.stringify(this.form)))
          params.snils = params.snils.replace(/[^\d]/g, '')
          this.$store.dispatch(this.data ? 'orders/fetchUpdatePayer' : 'orders/fetchCreatePayer', params)
            .then((res) => {
              if (res.data) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: 'Сохранено!',
                  type: 'success',
                })
                this.$bvModal.hide('modalPayerDetails')
              } else {
                if (res.message) {
                  this.$notify({
                    group: 'center',
                    title: ``,
                    text: res.message,
                    type: 'error',
                  })
                } else {
                  this.$notify({
                    group: 'center',
                    title: ``,
                    text: 'Что-то пошло не так, попробуйте еще раз',
                    type: 'error',
                  })
                }
              }
            })
        }
      },
      NumbersOnly(evt) {
        evt = (evt) ? evt : window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()

        } else {
          return true
        }
      },
      removeEmptyValues(object) {
        return typeof (!!object && object === 'object' && !Array.isArray(object))
          ? Object.fromEntries(Object.entries(object).filter(([_, value]) => value))
          : object
      },
    },
  }
</script>
