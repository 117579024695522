<template>
	<div>
	</div>
</template>

<script>
  export default {
    name: 'home',
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>
