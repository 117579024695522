<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb d-none d-md-flex">
			<li class="breadcrumb-item">
				<router-link to="/">Главная</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link to="/">Личный кабинет</router-link>
			</li>
			<li class="breadcrumb-item active">Заказы</li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
			<h1 class="page-header">Заказы на обучение</h1>
		</div>
		<!-- END page-header -->

		<div class="mw-1100px">
			<div class="border border-1 border-radius-lg border-primary px-25px py-15px mb-3 d-flex align-items-center" v-if="items" v-for="item in items" :key="item.id">
				<span class="fs-24px fw-bold me-4">Заказ №{{item.id}}</span>
				<span>от {{item.created_at}}</span>
				<router-link :to="'/orders/' + item.id" class="btn btn-primary btn-theme fw-bold ms-auto">Подробнее</router-link>
			</div>

			<ul class="pagination mt-4" v-if="pages.length > 1">
				<div class="page-item" :class="page <= 1 ? 'disabled' : ''">
					<router-link :to="'/orders/?page=' + (page - 1)" class="page-link">«</router-link>
				</div>
				<div class="page-item" v-for="item in pages" :key="item + '_page'">
					<router-link :to="'/orders/?page=' + item" class="page-link" :class="page.toString() === item.toString() ? 'active' : ''">{{item}}</router-link>
				</div>
				<div class="page-item" :class="page >= last_page ? 'disabled' : ''">
					<router-link :to="'/orders/?page=' + (page + 1)" class="page-link">»</router-link>
				</div>
			</ul>
		</div>
	</div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Order',
    data() {
      return {
        page: 1,
      }
    },
    computed: {
      ...mapGetters({
        items: 'orders/itemsByPage',
        last_page: 'orders/last_page',
      }),
	  pages() {
        let pages = []
		for (let i = 1, l = this.last_page; i <= l; i++) {
		  pages.push(i)
		}
		return pages
	  }
    },
    watch:{
      $route (to, from){
        console.log(from, to)
		if (to && from && to.query?.page !== from.query?.page) {
          this.page = to.query?.page
          this.getList()
		}
      }
    },
    created() {
      this.page = this.$route.query?.page || 1
      this.getList()
    },
    methods: {
      getList() {
        this.$store.dispatch('orders/fetchOrderItems', this.page).then(res => {
          console.log('RESULT', res)
		  if (res.request?.status === 401) {
            this.$store.dispatch('auth/logout')
		  }
		})
      },
    },
  }
</script>
