<template>
	<div>
		<!-- BEGIN login -->
		<div class="login login-v2 fw-bold">
			<!-- BEGIN login-cover -->
			<div class="login-cover">
				<div class="login-cover-img" v-bind:style="{ backgroundImage: 'url('+ bg.activeImg +')' }"></div>
				<div class="login-cover-bg"></div>
			</div>
			<!-- END login-cover -->

			<!-- BEGIN login-container -->
			<div class="login-container">
				<!-- BEGIN login-header -->
				<div class="login-header justify-content-center">
					<!--<div class="brand">
						<div class="d-flex align-items-center">
							<img class="logo-img" src="/assets/img/logo/pish-logo.svg" />
						</div>
					</div>-->
					<div class="fs-1 fw-bold text-center">Авторизация</div>
				</div>
				<!-- END login-header -->

				<!-- BEGIN login-content -->
				<div class="login-content">
					<ValidationObserver
							tag="form"
							ref="form_auth_observer"
							@submit.prevent="checkForm()"
							v-slot="{ invalid }"
							method="POST">

						<ValidationProvider
								tag="div"
								class="form-floating mb-20px"
								ref="form_auth_email"
								rules="required"
								name="E-mail"
								v-slot="{ errors }">

							<input
									type="text"
									class="form-control fs-14px h-40px"
									:class="errors.length ? 'is-invalid' : ''"
									placeholder=""
									name="form_auth_email"
									id="form_auth_email"
									@input="checkValidate('email')"
									@change="checkValidate('email')"
									v-model="form['email']" />

							<label for="form_auth_email" class="d-flex align-items-center text-gray-600 fs-14px">E-mail</label>

						</ValidationProvider>

						<ValidationProvider
								tag="div"
								class="form-floating mb-20px"
								ref="form_auth_password"
								rules="required"
								name="E-mail"
								v-slot="{ errors }">

							<input
									:type="typePassword"
									class="form-control fs-14px h-40px pe-50px"
									:class="errors.length ? 'is-invalid' : ''"
									placeholder="Пароль"
									name="form_auth_password"
									id="form_auth_password"
									@input="checkValidate('password')"
									@change="checkValidate('password')"
									v-model="form['password']" />

							<a
									href="#"
									@click.prevent="togglePassword"
									class="position-absolute translate-middle-y top-50 end-0 d-flex align-items-center justify-content-center w-45px h-45px text-decoration-none"
									:class="errors.length ? 'me-4' : ''">
								<i class="fas fa-eye" v-show="typePassword === 'text'"></i>
								<i class="fas fa-eye-slash" v-show="typePassword === 'password'"></i>
							</a>

							<label for="form_auth_email" class="d-flex align-items-center text-gray-600 fs-14px">Пароль</label>

						</ValidationProvider>

						<div class="text-end mt-n2">
							<RouterLink to="/forgot-password">Восстановить пароль</RouterLink>
						</div>

						<div class="form-check mb-20px d-flex align-items-center">
							<input class="form-check-input" type="checkbox" value="1" id="rememberMe" v-model="form['remember']" />
							<label class="form-check-label fs-14px text-gray-200 ms-2" for="rememberMe">
								Запомнить меня
							</label>
						</div>

						<button type="submit" class="btn btn-success d-block w-100 h-45px fs-14px btn-lg">Войти</button>

						<!--<div class="text-gray-500 fs-14px">
							Not a member yet? Click <a href="javascript:;">here</a> to register.
						</div>-->

					</ValidationObserver>
				</div>
				<!-- END login-content -->
			</div>
			<!-- END login-container -->
		</div>
		<!-- END login -->
		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<a class="title">
						{{props.item.title}}
					</a>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>
  import AppOptions from '../config/options.js'
  import { mapGetters } from 'vuex'
  import LogoImg from '../../public/assets/img/login-bg/login-bg.jpg'

  export default {
    created() {
      AppOptions.appEmpty = true
    },
    beforeRouteLeave(to, from, next) {
      AppOptions.appEmpty = false
      next()
    },
    data() {
      return {
        bg: {
          activeImg: LogoImg,
        },
        typePassword: 'password',
        form: {
          email: '',
          password: '',
          remember: false,
        },
      }
    },
    computed: {
      ...mapGetters({
        token: 'auth/token',
      }),
    },
    methods: {
      togglePassword: function () {
        this.typePassword = this.typePassword === 'password' ? 'text' : 'password'
      },
      checkValidate: function (name) {
        const provider = this.$refs[name]
        if (provider && provider.validate) {
          provider.validate()
        }
      },
      checkForm: async function () {
        const isValid = await this.$refs.form_auth_observer.validate()
        if (isValid) {
          const formData = new FormData();
          formData.append("login", this.form.email);
          formData.append("password", this.form.password);
          //formData.append("remember_me", this.form.remember);
          this.$store.dispatch('auth/fetchLogin', formData)
            .then((res) => {
              console.log('RESULT', res, this.token)
			  if (res.message) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: res.message,
                  type: 'success',
                })
			  }
              if (res.token) {
                this.$router.push({ path: '/' })
              }
            })
        }
      },
    },
  }
</script>
