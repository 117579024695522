import merge from 'deepmerge'

export default {
  SET_ORDER_ITEM(state, payload) {
    state.items[payload.id.toString()] = {
      ...state.items[payload.id.toString()],
      ...payload,
    }
  },
  SET_ORDERS_ITEMS(state, payload) {
    if (payload.data) {
      state.items = merge(state.items, payload.data.reduce((obj, item) => {
        obj[item.id.toString()] = item
        return obj
      }, {}))
      state.pagination.cur_ids = payload.data.map(item => item.id.toString())
    }
    if (payload.meta?.last_page) {
      state.pagination.last_page = payload.meta.last_page || 1
    }
  },
  SET_PAYER_TO_ITEM(state, payload) {
    state.items[payload.order_id.toString()] = {
      ...state.items[payload.order_id.toString()],
      ...{
        payment: {
          ...state.items[payload.order_id.toString()].payment,
          ...payload
        }
      },
    }
  },
  // idOrder, idProgram
  REMOVE_PROGRAM_ITEM(state, payload) {
    if (state.items[payload.idOrder]?.bids) {
      state.items[payload.idOrder].bids = state.items[payload.idOrder].bids.filter(item => item.id !== payload.idProgram)
    }
  },
  // idOrder, idProgram, idListener
  REMOVE_LISTENER_ITEM(state, payload) {
    if (state.items[payload.idOrder]?.bids) {
      state.items[payload.idOrder].bids = state.items[payload.idOrder].bids.filter(item => item.id !== payload.idProgram)
    }
  },
}
