<template>
	<svg :width="width" :height="height" :style="styleName" :class="className" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4286 2.2872H2.28591V13.7159H11.4286V14.8585H1.71391C1.56255 14.858 1.41753 14.7976 1.3105 14.6906C1.20347 14.5836 1.14311 14.4386 1.14258 14.2872V1.71586C1.14311 1.5645 1.20347 1.41948 1.3105 1.31245C1.41753 1.20542 1.56255 1.14506 1.71391 1.14453H11.4286V2.2872ZM10.6572 3.54386L14.6859 7.6012C14.9146 7.82986 14.9146 8.17253 14.6572 8.4012L10.6286 12.4299L9.82858 11.6299L12.9146 8.57253H3.99991V7.42986H12.9146L9.85724 4.34386L10.6572 3.54386Z" :fill="color"/>
	</svg>
</template>

<script>
  export default {
    name: 'IconLogout',
	props: {
      'width': {
		type: Number,
		default: 16,
	  },
	  'height': {
        type: Number,
        default: 16,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#6A6C6A'
	  },
      'className': {
        type: String,
        default: ''
      },
    },
    data() {
      return {}
    },
  }
</script>
