var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register register-with-news-feed"},[_vm._m(0),_c('div',{staticClass:"register-container"},[_vm._m(1),_c('div',{staticClass:"register-content"},[_c('form',{staticClass:"fs-13px",attrs:{"method":"POST"},on:{"submit":_vm.checkForm}},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"mb-4 pb-5"},[_vm._v(" Already a member? Click "),_c('router-link',{attrs:{"to":"/user/login-v3"}},[_vm._v("here")]),_vm._v(" to login. ")],1),_c('hr',{staticClass:"bg-gray-600 opacity-2"}),_c('p',{staticClass:"text-center text-gray-600"},[_vm._v(" © Color Admin All Right Reserved 2021 ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-feed"},[_c('div',{staticClass:"news-image",staticStyle:{"background-image":"url(/assets/img/login-bg/login-bg-15.jpg)"}}),_c('div',{staticClass:"news-caption"},[_c('h4',{staticClass:"caption-title"},[_c('b',[_vm._v("Color")]),_vm._v(" Admin App")]),_c('p',[_vm._v(" As a Color Admin app administrator, you use the Color Admin console to manage your organization’s account, such as add new users, manage security settings, and turn on the services you want your team to access. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-header mb-25px h1"},[_c('div',{staticClass:"mb-1"},[_vm._v("Sign Up")]),_c('small',{staticClass:"d-block fs-15px lh-16"},[_vm._v("Create your Color Admin Account. It’s free and always will be.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"mb-2"},[_vm._v("Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"row gx-3"},[_c('div',{staticClass:"col-md-6 mb-2 mb-md-0"},[_c('input',{staticClass:"form-control fs-13px",attrs:{"type":"text","placeholder":"First name"}})]),_c('div',{staticClass:"col-md-6"},[_c('input',{staticClass:"form-control fs-13px",attrs:{"type":"text","placeholder":"Last name"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"mb-2"},[_vm._v("Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{staticClass:"form-control fs-13px",attrs:{"type":"text","placeholder":"Email address"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"mb-2"},[_vm._v("Re-enter Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{staticClass:"form-control fs-13px",attrs:{"type":"text","placeholder":"Re-enter email address"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"mb-2"},[_vm._v("Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{staticClass:"form-control fs-13px",attrs:{"type":"password","placeholder":"Password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check mb-4"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","value":"","id":"agreementCheckbox"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"agreementCheckbox"}},[_vm._v(" By clicking Sign Up, you agree to our "),_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Terms")]),_vm._v(" and that you have read our "),_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Data Policy")]),_vm._v(", including our "),_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Cookie Use")]),_vm._v(". ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('button',{staticClass:"btn btn-primary d-block w-100 btn-lg h-45px fs-13px",attrs:{"type":"submit"}},[_vm._v("Sign Up")])])
}]

export { render, staticRenderFns }