import state from './ordersState'
import mutations from './ordersMutations'
import getters from './ordersGetters'
import actions from './ordersActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
