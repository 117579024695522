<template>
    <span>
		<span @click="showModal()">
			<slot name="button"></slot>
		</span>

		<b-modal :id="'modalPayerDetails'" size="lg" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">{{data ? 'Редактировать' : 'Добавить'}} данные плательщика</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('modalPayerDetails')"></a>
			</template>

			<div class="d-flex">
			  <button
					  class="me-20px"
					  :class="'btn' + (type_face === type.type ? ' btn-primary' : '  btn-outline-primary')"
					  v-for="type in types"
					  :key="type.type"
					  @click="type_face = type.type">{{type.name}}</button>
			</div>

			<FizForm v-show="type_face === 'FIZ'" :data="data" :id="id" @closeModal="$bvModal.hide('modalPayerDetails')" />
			<LegalForm v-show="type_face === 'UR'" :data="data" :id="id" @closeModal="$bvModal.hide('modalPayerDetails')" />
			<IPForm v-show="type_face === 'IP'" :data="data" :id="id" @closeModal="$bvModal.hide('modalPayerDetails')" />

			<div slot="modal-footer"></div>
		</b-modal>
		<notifications group="center" position="top center" :duration="10000" >
		   <template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<a class="title">
						{{props.item.title}}
					</a>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
		  </template>
		</notifications>
    </span>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import appOptions from '../../config/options'
  import FizForm from '../form/FizForm'
  import LegalForm from '../form/LegalForm'
  import IPForm from '../form/IPForm'

  export default {
    name: 'PayerDetailsEditModal',
    components: { FizForm, LegalForm, IPForm },
    props: ['button', 'data', 'id'],
    data() {
      return {
        type_face: 'UR',
        types: [
          {
            type: 'UR',
            name: 'Юридическое лицо',
          },
          {
            type: 'FIZ',
            name: 'Физическое лицо',
          },
          {
            type: 'IP',
            name: 'Индивидуальный предприниматель',
          },
        ],
      }
    },
    computed: {
      dataItems() {
        return this.data
	  }
    },
    watch: {
      dataItems(newVal, oldVal) {
        console.log('new', newVal)
        this.type_face = newVal.type_face
	  }
    },
    mounted() {
      if (this.data) {
        this.type_face = this.data.type_face
      }
    },
    destroyed() {
    },
    methods: {
      showModal() {
        this.$bvModal.show('modalPayerDetails')
      },
    },
  }
</script>
