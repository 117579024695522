<template>
    <span>
		<a href="javascript:;" @click.prevent="showModal()">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'DeleteProgramModal' + idBid" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Удалить программу и всех слушателей из нее?</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('DeleteProgramModal')"></a>
			</template>

			<div class="text-center mt-n3 mb-3">Отменить это действие будет невозможно</div>

			<div slot="modal-footer" class="d-flex justify-content-between w-100 gap-3">
				<button type="button" class="btn btn-primary btn-lg flex-1" @click="send">
					Удалить
				</button>
				<button type="button" class="btn btn-gray btn-lg text-dark flex-1" @click="$bvModal.hide('DeleteProgramModal' + idBid)">
					Отменить
				</button>
			</div>
		</b-modal>
    </span>
</template>

<script>
  import axios from 'axios'
  import appOptions from '../../config/options'

  export default {
    name: 'DeleteProgramModal',
    props: ['button', 'id', 'idOrder', 'idBid'],
    data() {
      return {}
    },
    computed: {
      token() {
        return this.$store.getters['auth/token']
      },
    },
    watch: {},
    mounted() {
    },
    destroyed() {
    },
    methods: {
      showModal() {
        this.$bvModal.show('DeleteProgramModal' + this.idBid)
      },
      send() {
        axios({
          url: appOptions.api + '/orders/bids/deleteProgram/',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: {
            id: this.id,
            bid_id: this.idBid,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res) {
              this.$store.dispatch('orders/fetchOrderItem', this.idOrder)
              this.$bvModal.hide('DeleteProgramModal' + this.idBid)
            }
          })
      },
    },
  }
</script>
